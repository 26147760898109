<template>
  <ion-content>
    <div class="auth-wrapper">

      <div class="auth-container">
        <div class="logo">
          <a href="/">
            <img width="150" src="/assets/icon/icon.png"/>
          </a>
        </div>
        <h3 class="header"> Xact Releases </h3>

        <form @submit.prevent="login">
          <ion-item>
            <ion-label position="fixed"> Email </ion-label>
            <ion-input type="text"
                       inputmode="email"
                       v-model="email"
                       @keyup.enter="login" />
          </ion-item>

          <ion-item>
            <ion-label position="fixed"> Passwort </ion-label>
            <ion-input type="password"
                       inputmode="text"
                       v-model="password"
                       @keyup.enter="login" />
          </ion-item>

          <div class="error-wrapper">
            <div class="error" :class="{ active: !!error }">
              {{ error }}
            </div>
          </div>

          <ion-button type="submit" expand="block" fill="solid">
            Login
          </ion-button>
        </form>
        <ion-button type="submit" expand="block" fill="clear" class="reset-password" disabled>
          Forgot password
        </ion-button>
      </div>

      <div class="auth-footer">
        footer
      </div>

    </div>
  </ion-content>
</template>

<script lang="ts" src="./login.ts" />
<style scoped src="./login.css" />
