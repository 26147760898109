import {
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  loadingController,
} from '@ionic/vue';
import { defineComponent, ref } from 'vue';

import firebase from 'firebase';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const email = ref('');
    const password = ref('');
    const error = ref('');

    const login = async () => {
      // Clean up
      error.value = '';
      email.value = email.value.trim();
      password.value = password.value.trim();

      // Show loading animation
      const loading = await loadingController
        .create({
          message: 'Logging in...',
        });
      await loading.present();

      // Pre check inputs
      if (!email.value) {
        error.value = 'Email is required';
        loading.dismiss();
        return;
      }
      if (!(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(email.value))) {
        error.value = 'Email is not valid';
        loading.dismiss();
        return;
      }
      if (!password.value) {
        error.value = 'Password is required';
        loading.dismiss();
        return;
      }

      // Try to log in
      try {
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);
        await router.push('/');
      } catch(_) {
        error.value = 'Incorrect username or password';
      }
      loading.dismiss();
    };

    return {
      email,
      password,
      error,
      login,
    };
  }
});
